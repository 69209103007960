import { toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, vModelText as _vModelText, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5b9a8e48"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sign-in-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Input = _resolveComponent("Input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("h3", { class: "error" }, _toDisplayString($setup.showError), 513), [
      [_vShow, $setup.showError != '']
    ]),
    _createElementVNode("form", {
      onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => ($setup.resetPassword && $setup.resetPassword(...args)), ["prevent"]))
    }, [
      _withDirectives(_createElementVNode("input", {
        type: "password",
        placeholder: "New password",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.password) = $event)),
        required: ""
      }, null, 512), [
        [
          _vModelText,
          $setup.password,
          void 0,
          { lazy: true }
        ]
      ]),
      _withDirectives(_createElementVNode("input", {
        type: "password",
        placeholder: "Repeat password",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.repPassword) = $event)),
        required: ""
      }, null, 512), [
        [
          _vModelText,
          $setup.repPassword,
          void 0,
          { lazy: true }
        ]
      ]),
      _createVNode(_component_Input, {
        type: "submit",
        value: "Sign in"
      })
    ], 32)
  ]))
}