import { httpsCallable } from "firebase/functions";
import { functions, auth } from "@/firebase";

const checkPremium = async (store) => {
  try {
    const user = auth.currentUser;
    if (user) {
      const checkPremium: any = httpsCallable(functions, "checkPremiumv2");
      const res = await checkPremium({
        text: { uid: user.uid },
      });

      console.log(res);

      store.commit("SET_PREMIUM", res.data.status == "premium");
      store.commit("SET_HAVE_BEEN_PREMIUM", res.data.haveBeenPremium);
      store.commit("SET_HANDLED_BY_APPLE", !!res.data.handledByApple);

      return res.data;
    }
  } catch (error: any) {
    console.log(error);

    if (error.message)
      store.commit(
        "SET_ERROR",
        `Failed to check subscription: ${error.message}`
      );

    return null;
  }
};

export default checkPremium;
