<template>
  <div class="background">
    <div class="wrapper">
      <ForgotPasswordComp />
    </div>
  </div>
</template>

<script>
import ForgotPasswordComp from "@/components/Auth/ForgotPasswordComp.vue";

export default {
  name: "ForgotPassword",
  components: {
    ForgotPasswordComp,
  },
  setup() {
    return {};
  },
};
</script>

<style scoped>
img {
  height: 75px;
}
.background {
  background-color: var(--background-blue);
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  flex-direction: column;
}
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-height: 75%;
}
</style>
