
import { auth, db } from "@/firebase";
import router from "@/router";
import { TourStatus } from "@/store/types/TourStatus";
import { Routes } from "@/types/Routes";
import { doc, setDoc } from "firebase/firestore";
import { useStore } from "vuex";

export default {
  name: "SignUpWelcome",
  components: {},
  setup() {
    const continueTour = async () => {
      if (!auth.currentUser) return;

      // Jag får inte skriva här av någon anledning, den bli blockad av emailVerified() funktionen i firebase.
      // Något lurt med hur den verifierar det
      try {
        await setDoc(
          doc(db, "users", auth.currentUser.uid),
          {
            tourStatus: TourStatus.THIS_DOWNLOAD,
          },
          { merge: true }
        );
        router.replace(Routes.SIGNUPTHISDOWNLOAD);
      } catch (error) {
        console.log(error);
      }
    };

    return {
      continueTour,
    };
  },
};
