import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueCookieAcceptDecline from "vue-cookie-accept-decline";
import "vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css";
import VueGtag from "vue-gtag";

createApp(App)
  .use(store)
  .use(router)
  .use(VueGtag, {
    config: { id: "AW-10951781605" },
  })
  .component("vue-cookie-accept-decline", VueCookieAcceptDecline)
  .mount("#app");
