import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, OAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_API_KEY,

  authDomain: process.env.VUE_APP_AUTH_DOMAIN,

  databaseURL: process.env.VUE_APP_DATABASE_URL,

  projectId: process.env.VUE_APP_PROJECT_ID,

  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,

  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,

  appId: process.env.VUE_APP_APP_ID,

  measurementId: process.env.VUE_APP_MESUREMENT_ID,
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

const db = getFirestore(app);

const storage = getStorage(app);

const functions = getFunctions(app, "europe-west3");

const googleProvider = new GoogleAuthProvider();

const appleProvider = new OAuthProvider("apple.com");

export { auth, db, storage, functions, googleProvider, appleProvider };
