
import { provide, ref } from "vue";
import ManageAccount from "./Modals/ManageAccount.vue";
import download from "./getDownload";
import ToggleButton from "@/components/ToggleButton.vue";

export default {
  name: "DownloadComp",
  components: { ManageAccount, ToggleButton },
  setup() {
    const showManage = ref(false);

    const OSName = ref("Unknown OS");
    if (navigator.userAgent.indexOf("Win") != -1) OSName.value = "Windows";
    if (navigator.userAgent.indexOf("Mac") != -1) OSName.value = "MacOS";

    function toggleEvent(value: boolean) {
      if (typeof value === "boolean") {
        if (value) {
          OSName.value = "MacOS";
        } else {
          OSName.value = "Windows";
        }
      }
    }

    const closeMange = () => {
      showManage.value = false;
    };

    provide("closeMange", { closeMange });

    return {
      download,
      showManage,
      OSName,
      toggleEvent,
    };
  },
};
