
import SignUpRemote from "@/components/Auth/SignUpRemote.vue";

export default {
  name: "WelcomeView",
  components: {
    SignUpRemote,
  },
  setup() {
    return {};
  },
};
