
import { auth, db, functions } from "@/firebase";
import router from "@/router";
import { TourStatus } from "@/store/types/TourStatus";
import { doc, setDoc } from "firebase/firestore";
import { ref } from "vue";
import { isMobile } from "./isMobile";
import download from "../getDownload";
import { Routes } from "@/types/Routes";
import { httpsCallable } from "@firebase/functions";

export default {
  name: "SignUpWelcome",
  components: {},
  setup() {
    const mobile = isMobile();
    const isLoading = ref(false);

    const OSName = ref("Unknown OS");
    if (mobile) {
      if (navigator.userAgent.indexOf("Win") != -1) OSName.value = "Windows";
      if (navigator.userAgent.indexOf("Mac") != -1) OSName.value = "MacOS";
    }

    const skipWalktrough = () => {
      if (!auth.currentUser) return;
      setDoc(
        doc(db, "users", auth.currentUser.uid),
        {
          tourStatus: TourStatus.COMPLETE,
        },
        { merge: true }
      );
      router.replace(Routes.DOWNLOAD);
    };

    function toggleEvent(value: boolean) {
      if (typeof value === "boolean") {
        if (value) {
          OSName.value = "MacOS";
        } else {
          OSName.value = "Windows";
        }
      }
    }

    const sendEmail = async () => {
      isLoading.value = true;
      if (!auth.currentUser) return;

      const sendDownloadEmail = httpsCallable(functions, "sendDownloadEmailv2");

      await sendDownloadEmail().then((result) => {
        console.log(result);
      });

      await setDoc(
        doc(db, "users", auth.currentUser.uid),
        {
          tourStatus: TourStatus.COMPLETE,
        },
        { merge: true }
      );

      setTimeout(() => {
        isLoading.value = false;
        router.replace(Routes.SIGNUPCOMPLETE);
      }, 1000);
    };

    return {
      sendEmail,
      mobile,
      OSName,
      toggleEvent,
      download,
      isLoading,
      skipWalktrough,
    };
  },
};
