<template>
  <label
    :for="id + '_button'"
    :class="{ active: isActive }"
    class="toggle__button"
  >
    <span class="toggle__label">{{ enableText }}</span>

    <input
      type="checkbox"
      :disabled="disabled"
      :id="id + '_button'"
      v-model="checkedValue"
    />

    <span class="toggle__switch"></span>
    <span class="toggle__label">{{ disabledText }}</span>
  </label>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    labelEnableText: {
      type: String,
      default: "On",
    },

    labelDisableText: {
      type: String,
      default: "Off",
    },
    id: {
      type: String,
      default: "primary",
    },
    defaultState: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentState: this.defaultState,
    };
  },
  watch: {
    defaultState: function defaultState() {
      this.currentState = Boolean(this.defaultState);
    },
  },
  computed: {
    // currentState() {
    //     return this.defaultState;
    // },
    isActive() {
      return this.currentState;
    },
    enableText() {
      return this.labelEnableText;
    },
    disabledText() {
      return this.labelDisableText;
    },
    checkedValue: {
      get() {
        return this.currentState;
      },
      set(newValue) {
        this.currentState = newValue;
        this.$emit("change", newValue);
      },
    },
  },
};
</script>

<style scoped>
.toggle__button {
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
  display: flex;
  padding: 1rem;
}
.toggle__button input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  width: 1px;
  height: 1px;
}
.toggle__button .toggle__switch {
  display: inline-block;
  height: 20px;
  border-radius: 10px;
  width: 50px;
  background: var(--dark-blue);
  box-shadow: inset 0 0 1px var(--dark-blue);
  position: relative;
  margin-left: 10px;
  margin-right: 10px;
  transition: all 0.25s;
}
.toggle__button .toggle__switch::after,
.toggle__button .toggle__switch::before {
  content: "";
  position: absolute;
  display: block;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  left: 1px;
  top: 1px;
  transform: translateX(0);
  transition: all 0.25s cubic-bezier(0.5, -0.6, 0.5, 1.6);
}
.toggle__button .toggle__switch::after {
  background: var(--background-blue);
  box-shadow: 0 0 1px #666;
}
.toggle__button .toggle__switch::before {
  background: var(--background-blue);
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1);
  opacity: 0;
}
.active .toggle__switch {
  background: var(--dark-blue);
  box-shadow: inset 0 0 1px var(--dark-blue);
}
.active .toggle__switch::after,
.active .toggle__switch::before {
  transform: translateX(50px - 18px);
}
.active .toggle__switch::after {
  left: 31px;
  background: var(--background-blue);
  box-shadow: 0 0 1px var(--background-blue);
}
</style>
