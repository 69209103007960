
import { ref } from "vue";
import {
  verifyPasswordResetCode,
  confirmPasswordReset,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { auth } from "../../firebase";
import { useStore } from "vuex";
import router from "@/router";

export default {
  name: "ResetPassword",
  props: {
    parameters: {
      type: Object,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const password = ref("");
    const repPassword = ref("");
    const showError = ref("");

    function handleResetPassword(auth, actionCode) {
      if (password.value !== repPassword.value) {
        alert("Passwords do not match");
        return;
      }

      verifyPasswordResetCode(auth, actionCode)
        .then((email) => {
          const newPassword = password.value;
          confirmPasswordReset(auth, actionCode, newPassword)
            .then(async () => {
              console.log("Password reset");
              await signInWithEmailAndPassword(auth, email, newPassword);
              router.push("/signin");
            })
            .catch((error) => {
              console.log(error);
              showError.value = error.code;
            });
        })
        .catch((error) => {
          console.log(error);
          showError.value = error.code;
        });
    }

    console.log(props.parameters);

    function resetPassword() {
      handleResetPassword(auth, props.parameters.oobCode);
    }

    return {
      password,
      repPassword,
      resetPassword,
      showError,
    };
  },
};
