<template>
  <div class="modal-background">
    <div class="modal">
      <div v-if="isLoading">Loading account information...</div>
      <SubscribedAccount v-else :subscription="subscription">
        <slot></slot>
      </SubscribedAccount>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "@/firebase";
import { useStore } from "vuex";
import SubscribedAccount from "./SubscribedAccount.vue";

export default {
  name: "ManageAccount",
  setup() {
    const isLoading = ref(false);
    const store = useStore();
    const subscription = ref(null);
    const fetchSubs = async () => {
      isLoading.value = true;
      const subsRef = collection(
        db,
        "customers",
        store.state.user.uid,
        "subscriptions"
      );
      const subsQuery = query(
        subsRef,
        where("status", "in", ["trialing", "active", "past_due", "unpaid"])
      );
      subscription.value = await getDocs(subsQuery).then((sub) =>
        sub.docs.length > 0 ? sub.docs[0].data() : null
      );
      isLoading.value = false;
    };
    fetchSubs();
    return {
      isLoading,
      subscription,
      fetchSubs,
    };
  },
  components: { SubscribedAccount },
};
</script>

<style scoped>
.modal-background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal {
  height: 45vh;
  width: 35vw;
  background-color: var(--background-blue);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  border-radius: 0.5rem;
  color: var(--dark-blue);
  font-family: Nunito;
  box-shadow: 4px 0px 75px -22px rgba(0, 0, 0, 0.51);
  -webkit-box-shadow: 4px 0px 75px -22px rgba(0, 0, 0, 0.51);
  -moz-box-shadow: 4px 0px 75px -22px rgba(0, 0, 0, 0.51);
  text-align: center;
}
h1 {
  padding: 1rem;
  font-size: 1.5rem;
}
@media screen and (max-width: 994px) {
  .modal {
    height: 50vh;
    width: 80vw;
  }
}
</style>
