
import { auth, db } from "@/firebase";
import router from "@/router";
import { Routes } from "@/types/Routes";

export default {
  name: "SignUpWelcome",
  components: {},
  setup() {
    const continueTour = async () => {
      if (!auth.currentUser) return;

      router.replace(Routes.DOWNLOAD);
    };

    return {
      continueTour,
    };
  },
};
