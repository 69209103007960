export enum Routes {
  LANDING = "/",
  ENTERPRISE = "/enterprise",
  TOS = "/tos",
  PRIVACY = "/privacy",
  COOKIES = "/cookies",
  RELEASENOTES = "/releasenotes",
  SIGNIN = "/signin",
  FORGOTPASSWORD = "/forgot-password",
  SIGNUP = "/signup",
  SIGNUPWELCOME = "/signup/welcome",
  SIGNUPPLAN = "/signup/plan",
  SIGNUPTHISDOWNLOAD = "/signup/this-download",
  SIGNUPREMOTEDOWNLOAD = "/signup/remote-download",
  SIGNUPVERIFY = "/signup/verify",
  SIGNUPCOMPLETE = "/signup/complete",
  DOWNLOAD = "/download",
  USERACTION = "/useraction",
  REDIRECT = "/redirect",
}
