
import { ref, onBeforeMount, provide, inject } from "vue";
import ToggleButton from "@/components/ToggleButton.vue";
import ToggleButtonModal from "@/components/ToggleButtonModal.vue";
import { useStore } from "vuex";
import LoadingScreen from "../Modals/LoadingScreen.vue";
import UpsellScreen from "../Modals/UpsellScreen.vue";
import { db, functions } from "@/firebase";
import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  onSnapshot,
  doc,
} from "@firebase/firestore";
import { httpsCallable } from "@firebase/functions";
import router from "@/router";
import { Routes } from "@/types/Routes";
import { auth } from "@/firebase";
import { setDoc } from "firebase/firestore";
import { TourStatus } from "@/store/types/TourStatus";
import { useRoute } from "vue-router";
import checkPremium from "@/logic/checkPremium";

export default {
  name: "SignUpPlan",
  components: {
    ToggleButton,
    ToggleButtonModal,
    LoadingScreen,
    UpsellScreen,
  },
  props: {
    existingPlan: {
      type: null,
      required: false,
      default() {
        return false;
      },
    },
    closePlan: {
      type: Function,
      required: false,
      default() {
        return false;
      },
    },
  },
  setup(props) {
    const paymentPeriod = ref(false);
    const store = useStore();
    const isLoading = ref(true);
    const showUpsell = ref(false);
    const showConfirm = ref(false);
    const showComplete = ref(false);
    const route = useRoute();
    const products: any = ref([]);
    const windowSize = ref(window.innerWidth);
    const isChosen = ref({
      product: {
        id: "",
        name: "",
        prices: [],
        metadata: {
          maxDevice: 0,
        },
      },
      price: {
        id: "",
        unit_amount: 0,
        interval: "",
      },
    });

    const routeQuery = route.query;

    console.log("query", routeQuery);

    if (routeQuery.success) {
      checkPremium(store).then(() => {
        isLoading.value = false;
        showComplete.value = true;
      });
    } else if (routeQuery.canceled) {
      isLoading.value = false;
      alert("Payment canceled");
      router.replace(Routes.LANDING);
    }

    const getFeatureName = (feature: string) => {
      switch (feature) {
        case "files":
          return "Files";
        case "images":
          return "Images";
        case "folders":
          return "Folders";
        case "prioSupport":
          return "Priority Support";
        case "textLimit":
          return "Text Limit";
        case "maxDevice":
          return "Max Devices";
        default:
          return feature;
      }
    };

    onBeforeMount(() => {
      windowSize.value = window.innerWidth;
    }),
      window.addEventListener("resize", () => {
        windowSize.value = window.innerWidth;
      });

    const fetchProducts = async () => {
      isLoading.value = true;
      const productsRef = collection(db, "products");
      const productsQuery = query(productsRef, where("active", "==", true));
      const productsQuerySnap = await getDocs(productsQuery);
      productsQuerySnap.forEach(async (docSnap) => {
        const pricesRef = collection(db, "products", docSnap.id, "prices");
        const pricesQuerySnap = await getDocs(pricesRef);
        const data: any = {
          id: docSnap.id,
          ...docSnap.data(),
          prices: pricesQuerySnap.docs
            .map((price: any) => {
              return {
                id: price.id,
                ...price.data(),
              };
            })
            .filter((price: any) => price.active),
        };
        products.value.push(data);
        products.value.sort((a: any, b: any) => {
          return a.metadata.index - b.metadata.index;
        });
        if (data.name == "Captain") {
          isChosen.value.product = data;
          data.prices.forEach((price: any) => {
            if (price.interval == "year" && paymentPeriod) {
              isChosen.value.price = price;
            }
          });
        }
      });
      if (window.location.pathname !== Routes.SIGNUPPLAN) {
        isLoading.value = false;
      }
    };

    fetchProducts();
    console.log(props.existingPlan ? props.existingPlan : "nope");

    function toggleEvent(value: boolean) {
      if (typeof value === "boolean") {
        paymentPeriod.value = value;
        selectProduct(isChosen.value.product);
      }
    }

    let close = () => {
      console.log("close");
      router.replace(Routes.LANDING);
    };

    if (props.existingPlan) {
      const { closeMange }: any = inject("closeMange");
      close = closeMange;
    }

    const createSub = async (
      price: any,
      product: any,
      skipUpsell?: boolean
    ) => {
      try {
        if (!auth.currentUser || !store.state.user) {
          router.push(Routes.SIGNUP);
          return;
        }

        const updatePlan = async () => {
          const updateStripePlan = httpsCallable(
            functions,
            "updateStripePlanv2"
          );

          const res: any = await updateStripePlan({
            price: price.id,
            uid: store.state.user.uid,
          });

          console.log(res.data);

          if (res.data.status) {
            store.commit(
              "SET_PREMIUM",
              res.data.status == "premium" ? true : false
            );
            store.commit("SET_HAVE_BEEN_PREMIUM", res.data.haveBeenPremium);
          }

          isLoading.value = false;
          showConfirm.value = false;
          showComplete.value = true;
        };

        const subs = await getDocs(
          query(
            collection(db, "customers", store.state.user.uid, "subscriptions"),
            where("status", "in", ["trialing", "active", "past_due", "unpaid"])
          )
        );

        if (subs.docs.length > 0 && !showConfirm.value) {
          showConfirm.value = true;
          isChosen.value.product = product;
          isChosen.value.price = price;
          return;
        }

        isLoading.value = true;

        if (product.name == "Deck Cadet") {
          await updatePlan();
          return;
        }

        const collectionRef = collection(
          db,
          "customers",
          store.state.user.uid,
          "checkout_sessions"
        );

        const docRef = await addDoc(collectionRef, {
          price: price.id,
          success_url:
            window.location.origin + Routes.LANDING + "?success=true",
          cancel_url:
            window.location.origin + Routes.LANDING + "?canceled=true",
          trial_from_plan: false,
        });

        onSnapshot(docRef, (snap: any) => {
          const { error, url } = snap.data();
          if (error) {
            alert(`An error occured: ${error.message}`);
            isLoading.value = false;
            showConfirm.value = false;
          }
          if (url) {
            isLoading.value = false;
            showConfirm.value = false;
            window.location.assign(url);
          }
        });
      } catch (error: any) {
        isLoading.value = false;
        console.log(error);
        alert(error.message);
      }
    };

    provide("createSub", { createSub });

    const selectProduct = (product) => {
      if (paymentPeriod.value) {
        product.prices.forEach((price) => {
          if (price.interval == "month") {
            isChosen.value = { price: price, product: product };
          }
        });
      } else if (!paymentPeriod.value) {
        product.prices.forEach((price) => {
          if (price.interval == "year") {
            isChosen.value = { price: price, product: product };
          }
        });
      }
      console.log(
        isChosen.value.price.interval,
        isChosen.value.product.name,
        isChosen.value.product.id
      );
    };

    return {
      paymentPeriod,
      toggleEvent,
      isLoading,
      products,
      createSub,
      windowSize,
      isChosen,
      selectProduct,
      showUpsell,
      showConfirm,
      close,
      showComplete,
      getFeatureName,
      auth,
    };
  },
};
