<template>
  <div class="row">
    <div class="col">
      <h2>Subscribed Account</h2>
      <h3>
        Current plan: {{ $store.state.isPremium ? "Captain" : "Deck Cadet" }}
      </h3>
      <button
        v-if="!$store.state.handledByApple"
        :disabled="isLoading"
        @click="openCustomerPortal"
      >
        Open my billing portal
      </button>
      <h3 v-else>Your subscription is managed by Apple.</h3>
      <button class="secondary" @click="$store.dispatch('logout')">
        Sign out
      </button>
      <slot></slot>
    </div>
  </div>
  <teleport to="body">
    <LoadingScreen v-if="isLoading" />
  </teleport>
  <teleport to="body">
    <ChangePlan v-if="showChangePlan" :subscription="subscription">
      <button class="secondary" @click="() => (showChangePlan = false)">
        Close
      </button>
    </ChangePlan>
  </teleport>
</template>

<script>
import { httpsCallable } from "firebase/functions";
import { functions } from "@/firebase";
import LoadingScreen from "./LoadingScreen.vue";
import ChangePlan from "./ChangePlan.vue";
import { ref } from "vue";
export default {
  props: ["subscription"],
  setup() {
    const isLoading = ref(false);
    const showChangePlan = ref(false);

    async function openCustomerPortal() {
      isLoading.value = true;
      const functionRef = httpsCallable(
        functions,
        "ext-firestore-stripe-payments-createPortalLink"
      );
      const { data } = await functionRef({
        returnUrl: window.location.origin + "/download",
      });
      window.location.assign(data.url);
    }

    return {
      isLoading,
      openCustomerPortal,
      showChangePlan,
    };
  },
  components: { LoadingScreen, ChangePlan },
};
</script>

<style>
.row {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
}
.col {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
button {
  background-color: var(--dark-blue);
  color: var(--background-blue);
  border: 3px solid var(--dark-blue);
  border-radius: 0.5rem;
  width: 90%;
  height: 2.5rem;
  font-family: "Nunito";
  font-size: 1rem;
  cursor: pointer;
  margin-bottom: 0.5rem;
}
button:hover {
  background-color: var(--background-blue);
  color: var(--dark-blue);
}
.secondary {
  background-color: var(--background-blue);
  color: var(--dark-blue);
  margin-bottom: 1rem;
}
.secondary:hover {
  text-decoration: underline 1px solid var(--dark-blue);
}
h3 {
  margin: 0.5rem 0;
}
</style>
