<template>
  <div class="sign-in-wrapper">
    <img src="~@/assets/shipd.svg" alt="logotyp" />
    <p>
      Enter your email address <br> and we'll send you a reset link.
    </p>
    <form @submit.prevent="forgotPassword">
      <input
        type="email"
        placeholder="E-mail"
        v-model.lazy="forgot_password.email"
        required
      />
      <input type="submit" value="Send reset link" />
    </form>

    <router-link to="/signup"
      ><button>Don't have an account?</button></router-link
    >
    <router-link to="/signin"><button>Sign in</button></router-link>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "ForgotPassword",

  setup() {
    const forgot_password = ref({});
    const store = useStore();

    const forgotPassword = () => {
      store
        .dispatch("forgotPassword", forgot_password.value)
        .then((resp) => {
          console.log(resp);
          alert(resp)
        })
    };

    return {
      forgot_password,
      forgotPassword,
    };
  },
};
</script>

<style scoped>
img {
  padding-top: 1rem;
  height: 5rem;
}
.sign-in-wrapper {
  background-color: var(--background-blue);
  padding: 0.5rem;
  border-radius: 0.5rem;
  color: var(--dark-blue);
  font-family: Nunito;
}
.forms {
  display: flex;
  min-height: 100vh;
}

form {
  flex: 1 1 0%;
  padding: 2rem 3rem 0rem;
}

h2 {
  font-size: 1.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 0;
  margin-bottom: 2px;
  color: var(--dark-blue);
}

input {
  appearance: none;
  border: none;
  outline: none;
  background: none;

  display: block;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  padding: 0.5rem 0rem;
  color: var(--dark-blue);
  font-family: Nunito;
}
input::placeholder {
  color: var(--primary-white);
}
input:not([type="submit"]) {
  border-bottom: 1px solid var(--dark-blue);
}
form input[type="submit"] {
  background-color: var(--dark-blue);
  color: var(--background-blue);
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  cursor: pointer;
}
button {
  appearance: none;
  border: none;
  outline: none;
  background: none;

  color: var(--dark-blue);
  text-decoration: underline;
  padding-bottom: 1rem;
  cursor: pointer;
  font-family: Nunito;
}
</style>
